import React, { useState, useEffect, createContext } from 'react';
import Client from 'shopify-buy';

const client = Client.buildClient({
  domain: `mecbikes.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
});

//console.log('loaded: CartContext ');

const defaultState = {
  cart: {},
};

const CartContext = createContext(defaultState);
export default CartContext;

export function CartContextProvider({ children }) {
  const [checkout, setCheckout] = useState(
    JSON.parse(
      typeof window !== 'undefined' ? localStorage.getItem('mec_checkout') : null
    )
  );

  //console.log('checkout: ', checkout);

  const [successfulOrder, setSuccessfulOrder] = useState(null);
  const checkoutId = checkout?.id;

  //console.log('checkoutId: ', checkoutId);

  useEffect(() => {
    const getCheckout = async () => {
      if (checkoutId && typeof window !== 'undefined') {
        const fetchedCheckout = await client.checkout.fetch(checkoutId);
        console.log('fetchedCheckout: ', fetchedCheckout)
        if (fetchedCheckout.completedAt != null) {
          localStorage.removeItem('mec_checkout');
          setCheckout(null);
          setSuccessfulOrder(fetchedCheckout);
        } else {
          setCheckout(fetchedCheckout);
          localStorage.setItem('mec_checkout', JSON.stringify(fetchedCheckout));
        }
      }
    };

    getCheckout();
  }, [setCheckout, setSuccessfulOrder, checkoutId]);

  async function getProductById(productId) {
    const product = await client.product.fetch(productId);
    return product;
  }
  
  // in cart
  const updateCartLineItem = async ({ variantId, quantity }) => 
  {
    let newCheckout = checkout || (await client.checkout.create());
    const lineItemVariant = newCheckout.lineItems?.find(
      lineItem => lineItem.variant.id === variantId
    );
    newCheckout = await client.checkout.updateLineItems(newCheckout.id, [{
        id: lineItemVariant.id,
        quantity: quantity,
      },
    ]);
    setCheckout(newCheckout);
    setSuccessfulOrder(null);
    if (typeof window !== 'undefined') {
      localStorage.setItem('mec_checkout', JSON.stringify(newCheckout));
    }
    setAddToCart(true)
    setTimeout(() => {
      addCart(false);
    }, 1000);
  };

  // product page
  const updateLineItem = async ({ variantId, quantity }) => 
  {  
    let newCheckout = checkout || (await client.checkout.create());
    const lineItemVariant = newCheckout.lineItems?.find(
      lineItem => lineItem.variant.id === variantId
    );

    if (lineItemVariant) {
      const newQuantity = lineItemVariant.quantity + quantity;

      if (newQuantity) {
        newCheckout = await client.checkout.updateLineItems(newCheckout.id, [
          {
            id: lineItemVariant.id,
            quantity: newQuantity,
          },
        ]);
      } else {
        newCheckout = await client.checkout.removeLineItems(newCheckout.id, [
          lineItemVariant.id,
        ]);
      }
    } else {
      newCheckout = await client.checkout.addLineItems(newCheckout.id, [
        {
          variantId,
          quantity,
        },
      ]);
    }

    setCheckout(newCheckout);
    setSuccessfulOrder(null);
    if (typeof window !== 'undefined') {
      localStorage.setItem('mec_checkout', JSON.stringify(newCheckout));
    }

    setAddToCart(true)
    setTimeout(() => {
      addCart(false);
    }, 1000);
  };

  const removeLineItem = async lineItemId => {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      lineItemId,
    ]);

    setCheckout(newCheckout);
  };

  const dismissSuccessfulOrder = () => {
    setSuccessfulOrder(null);
  };

  const [addToCart, setAddToCart] = useState(false)
  const addCart = (e) => {
    setAddToCart(e);
  };

  const [cartOpen, setCartOpen] = useState(false)

  return (
    <CartContext.Provider
      value={{
        updateCartLineItem,
        cartOpen,
        setCartOpen,
        checkout,
        updateLineItem,
        removeLineItem,
        getProductById,
        successfulOrder,
        dismissSuccessfulOrder,
        addToCart,
        addCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
}