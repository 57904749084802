import "./src/css/global.css";
import React from 'react';
import { ProductContextProvider } from './src/context/product_context';
import { CartContextProvider } from './src/context/cart_context';

export const wrapRootElement = ({ element }) => (
  <ProductContextProvider>
    <CartContextProvider>{element}</CartContextProvider>
  </ProductContextProvider>
);

export const wrapPageElement = ({ element }) => (
  <div>
    {element}
  </div>
);